import ApiRequest from '../../services/apiRequest'

export const GET_CERTIFICATES = async (context, params) => {
  return await ApiRequest.getCertificates(params)
    .then(response => {
      if (response.data.data) context.commit('GET_CERTIFICATES', response.data.data)
      return response.data.meta
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_CERTIFICATE = async (context, params) => {
  return await ApiRequest.getCertificate(params)
    .then(response => {
      if (response.data) context.commit('GET_CERTIFICATES', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const SET_CERTIFICATE = async (context, val) => {
  await context.commit('SET_CERTIFICATE', val)
}

export const GET_INSTRUMENT_QR = async (context, val) => {
  return await ApiRequest.getInstrumentQR(val)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_DESIGNATION = async (context, params) => {
  return await ApiRequest.getCafDesignation(params)
    .then(response => {
      if (response.data) context.commit('GET_DESIGNATION', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_CAF = async (context, params) => {
  return await ApiRequest.getCaf(params)
    .then(response => {
      if (response.data) {
        let res = response.data.data
        context.commit('GET_CAF', res)

        if (params.carousel) context.commit('CAF_CAROUSEL', res)
      }
      return response.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const DETAIL_CAF = async (context, id) => {
  return await ApiRequest.getCafDetail(id)
    .then(response => {
      context.commit('DETAIL_CAF', response.data.data)
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const READ_CAF = async (_context, id) => {
  return await ApiRequest.readCaf(id)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}
export const SUBMIT_CAF = async (_context, data) => {
  return await ApiRequest.submitCaf(data)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const MODAL_CAF = async (context, val) => {
  context.commit('MODAL_CAF', val)
}

export const SUBMIT_CAF_CHOICE_QUESTION = async (_context, data) => {
  return await ApiRequest.submitCafChoiceQuest(data)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const SUBMIT_CAF_ANSWER_QUESTION = async (_context, data) => {
  return await ApiRequest.submitCafAnswerQuest(data)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const SUBMIT_CAF_CONFIRM = async (_context, data) => {
  return await ApiRequest.confirmCaf(data)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}
