export default function () {
  return {
    certificates: [],
    certificate: {},
    instrument: {},
    cafs: [],
    caf: null,
    designations: [],
    carouselItems: []
  }
}
