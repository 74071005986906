<template>
  <div
    class="absolute h-full w-full sm:h-104 sm:w-98 bottom-0 sm:bottom-14 sm:right-16 z-50 bg-white sm:rounded-4xl bot-room flex flex-col overflow-hidden"
    v-if="isOpen && user && user.name"
  >
    <div
      class="sm:rounded-tl-4xl sm:rounded-tr-4xl bot-header py-2 px-4.1 flex justify-between space-x-3 items-center"
    >
      <div class="flex space-x-2">
        <div class="pt-0.5">
          <SvgBot />
        </div>
        <div class="flex flex-col">
          <div class="text-white font-medium">Support assistant</div>
          <div class="text-white text-13px">Virtual assistant</div>
        </div>
      </div>
      <div clasis="pr-1">
        <SvgClear class="cursor-pointer" @click="close" />
      </div>
    </div>

    <div ref="boxChat" class="flex-grow p-4 flex flex-col space-y-3.1 overflow-y-auto">
      <div
        v-for="(c, idx) in chats"
        :key="idx"
        class="max-w-300 rounded-xl"
        :class="c.user ? 'bg-hblue text-white text-right ml-auto' : 'bg-white text-left mr-auto'"
      >
        <div
          class="flex flex-col min-w-0"
          v-if="!c.user"
          :class="c.links && c.links.length > 0 ? 'pt-4' : 'py-3'"
        >
          <div
            class="font-Roboto text-sm text-hgray10 tracking-wide px-4 whitespace-pre-wrap"
            v-html="c.message"
          ></div>
          <div class="flex flex-col mt-2" v-if="c.links">
            <div
              v-for="(l, il) in c.links"
              :key="il"
              class="grid"
              :class="l.type == 'button' ? 'pb-3.5' : ''"
            >
              <div v-if="l.type == 'rating'" class="flex flex-col space-y-1">
                <div
                  class="font-Poppins pt-1 pb-2.2 px-4 font-medium text-13px text-hgray10 whitespace-pre-wrap"
                  v-html="l.title"
                ></div>
                <div
                  class="grid grid-cols-5 px-4 gap-3 sm:gap-6 place-items-center mx-auto z-10 relative bg-hgray9 rounded-br-xl rounded-bl-xl pt-3 pb-4"
                >
                  <div v-for="n in 5" :key="n">
                    <IconStar
                      class="fill-current cursor-pointer w-full h-full max-h-17.69 max-w-17.69"
                      @click="setRating(n)"
                      :class="n <= star ? `css-star-${star}` : 'text-hgray2'"
                    />
                  </div>
                </div>
              </div>

              <div v-else-if="l.type == 'button'" class="flex flex-col px-4">
                <button
                  @click="setChoice(l, idx)"
                  class="w-full rounded font-Poppins flex items-center justify-center text-white text-13px font-semibold bg-hblue py-2.1"
                >
                  {{ l.title }}
                </button>
              </div>

              <div
                v-else-if="l.type == 'device'"
                @click="setChoice(l, idx)"
                class="border-t border-hgray6 py-2.2 px-4 hover:bg-hgray flex space-x-4 justify-between items-center cursor-pointer"
              >
                <div class="font-Poppins font-medium text-13px text-hgray10 whitespace-pre-wrap">
                  {{ l.serialNumber ? l.serialNumber : '' }} {{ l.title }}
                </div>
                <SvgArrow class="stroke-current text-hgray1 h-3.5 w-2.2 stroke-2" />
              </div>

              <div
                v-else
                @click="setChoice(l, idx)"
                class="border-t border-hgray6 py-2.2 px-4 hover:bg-hgray flex space-x-4 justify-between items-center cursor-pointer"
              >
                <div
                  class="font-Poppins font-medium text-13px text-hgray10 whitespace-pre-wrap"
                  v-html="l.title"
                ></div>
                <SvgArrow class="stroke-current text-hgray1 h-3.5 w-2.2 stroke-2" />
              </div>
            </div>
          </div>

          <videoPlayer
            v-if="vid && idx == idxChat"
            class="bg-black video-player-box vjs-big-play-centered mt-2 mb-1"
            style="width: 300px"
            :options="playerOptions"
            :playsinline="true"
          />
        </div>

        <div v-if="c.user" class="font-Roboto tracking-wide px-4 py-3 text-sm">
          {{ c.message }}
        </div>
      </div>

      <div class="w-60 h-12 items-center text-left bg-white rounded-2xl px-3 flex" v-if="typed">
        <vue-typed-js
          :startDelay="1500"
          :strings="['Support assistant is typing']"
          :typeSpeed="30"
          :loop="false"
          :showCursor="false"
          class="h-12"
        >
          <span class="typing text-13px font-Roboto text-hgray10"></span>
        </vue-typed-js>
        <vue-typed-js
          class="h-12"
          :strings="['..']"
          :typeSpeed="85"
          :loop="true"
          :showCursor="false"
        >
          <span class="tracking-wide text-13px font-Roboto text-hgray10">
            .<span class="typing"></span>
          </span>
        </vue-typed-js>
      </div>
    </div>
    <div class="bg-white flex space-x-4 items-center justify-between py-4 px-5">
      <input
        type="text"
        placeholder="Type your message here....."
        class="outline-none w-full pl-2 font-Poppins bg-white text-hgray10"
        style="border-radius: 56px"
        v-model="message"
        v-on:keyup.enter="sendChat"
        :disabled="typed"
      />
      <button
        :disabled="!message || typed"
        class="btn btn-circle h-12 btn-secondary hover:bg-htealdark pl-0.8 border-0"
        @click="sendChat"
      >
        <SvgSend />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SvgClear from '@/assets/icons/icon-clear-bot.svg?inline'
import SvgArrow from '@/assets/icons/icon-arrow-nav.svg?inline'
import SvgBot from '@/assets/icons/bot.svg?inline'
import SvgSend from '@/assets/icons/icon-send.svg?inline'
import IconStar from '@/assets/icons/icon-star.svg?inline'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

export default {
  name: 'popup-bot-chat',
  components: {
    SvgClear,
    SvgBot,
    SvgSend,
    SvgArrow,
    IconStar,
    videoPlayer
  },
  data() {
    return {
      message: '',
      textchat: '',
      types: ['links', 'youtube', 'video', 'device', 'session', 'rating'],
      star: 0,
      vid: null,
      playerOptions: {
        width: 300,
        height: 169,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        controls: true,
        sources: []
      },
      idxChat: null
    }
  },
  watch: {
    chats: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length)
          setTimeout(() => {
            if (this.$refs.boxChat)
              this.$refs.boxChat.scrollTo({
                top: this.$refs.boxChat.scrollHeight,
                behavior: 'smooth'
              })
          }, 200)
      }
    },
    typed: {
      immediate: true,
      handler(val) {
        setTimeout(() => {
          if (val) this.AI_TYPED(false)
        }, 10000)
      }
    },
    isOpen: {
      immediate: true,
      handler(val) {
        if (val) this.checkSession()
      }
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_BOT']),
    ...mapActions('ticket', ['AI_SEND', 'AI_RESET', 'AI_TYPED']),
    close() {
      this.MODAL_BOT(false)
    },
    diffDate() {
      let result = 0
      if (this.ticket.id) {
        let lastAction = this.ticket.lastAction
        let currentDay = this.$moment()
        result = currentDay.diff(lastAction, 'minutes')
      }

      return result
    },
    checkSession() {
      let dt = this.diffDate()

      if (dt >= 10) {
        this.AI_RESET()
        this.toAPI(true, 'text')
      } else {
        if (!this.ticket.id) {
          this.AI_RESET()
          this.toAPI(true, 'text')
        }
      }
    },
    dataChat(val, type) {
      if (this.user && this.customer)
        return {
          sessionId: this.ticket.id,
          sourceId: this.ticket.number,
          type: type,
          tokenId: this.token,
          message: val ? 'hi' : this.textChat,
          userName: this.user.name,
          userEmail: this.uEmail,
          customerId: this.customer.id,
          customerName: this.customer.name,
          customerArea: this.customer.area,
          instruments: this.listInstrument,
          greet: val
        }
      else return null
    },
    sendChat() {
      let dt = this.diffDate()
      let val = false
      this.vid = null

      if (dt >= 10) {
        this.AI_RESET()
        val = true
      } else {
        if (!this.ticket.id) val = true
      }

      this.textChat = this.message

      this.toAPI(val, 'text')
      this.message = ''
    },
    async toAPI(val, type) {
      if (!this.token) {
        this.$toast.info('Please, allow notifications in your browser and reload page.')
        Notification.requestPermission()
      }

      if (this.typed) return true

      let form = await this.dataChat(val, type)

      await this.AI_SEND(form)
      this.AI_TYPED(true)
      this.textchat = ''
      setTimeout(() => {
        this.$refs.boxChat.scrollTo({ top: this.$refs.boxChat.scrollHeight, behavior: 'smooth' })
      }, 100)
    },
    setChoice(val, idx) {
      this.textChat = val.title
      this.idxChat = idx

      if (val.type == 'video') {
        this.playerOptions.sources = [
          {
            type: 'video/mp4',
            src: val.source
          }
        ]

        this.vid = val.source
      } else {
        if (val.type == 'device') {
          this.textChat = val.serialNumber + ' ' + val.manufacture + ' ' + val.title
        }
        if (val.type == 'ticket') {
          this.$router.push({ name: 'open.intro', query: { sourceId: this.ticket.id } })
          this.MODAL_BOT(false)
        }
        if (val.type == 'link' || val.type == 'youtube') window.open(val.source, '_blank')

        this.AI_TYPED(false)
        this.toAPI(false, val.type)
      }
    },
    setRating(val) {
      this.star = val
      this.textChat = val
      this.AI_TYPED(false)
      this.toAPI(false, 'rating')
    }
    // setDecor(val) {
    //   return val.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, '<b>$1</b>')
    // }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.bot
      },
      set: function (value) {
        this.MODAL_BOT(value)
      }
    },

    ...mapState({
      chats: state => state.ticket.aiChats,
      ticket: state => state.ticket.aiTicket,
      typed: state => state.ticket.aiTyped,
      token: state => state.auth.fcm,
      user: state => state.auth.user.client,
      customer: state => state.auth.user.customer.data,
      uEmail: state => state.auth.user.email
    }),
    ...mapGetters('auth', ['listInstrument'])
  }
}
</script>

<style scoped>
.vjs-tech {
  margin-left: auto;
  margin-right: auto;
}
</style>
