import api from './api'

class ApiRequest {
  // auth
  login(data) {
    return api.post('/api_v2/token', data)
  }
  me() {
    return api.get('/api_v2/me')
  }
  storeFcm(data) {
    return api.post('/api_v2/customer/devices/fcm', data)
  }
  deleteFcm(data) {
    return api.post(`/api_v2/customer/devices/fcm/delete`, data)
  }
  editUser(data) {
    return api.post('/api_v2/me', data)
  }

  getSwitchCustomers(data) {
    return api.get('/api_v2/customer/switch-customer', { params: data })
  }
  switchCustomer(customer_id) {
    return api.put(`/api_v2/customer/switch-customer/${customer_id}`)
  }

  // banners
  getBanners() {
    return api.get('/api_v2/customer/banners')
  }

  // notification
  getNotification() {
    return api.get('/api_v2/customer/ticket-notifications')
  }
  readNotification(id) {
    return api.put(`/api_v2/customer/ticket-notification/${id}/mark-as-read`)
  }

  // points
  totalPoint() {
    return api.get('/api_v2/customer/points/total')
  }
  getPoint(params) {
    return api.get('/api_v2/customer/points', { params: params })
  }

  // broadcast
  getBroadcast() {
    return api.get('/api_v2/customer/broadcasts')
  }
  getBroadcastCount() {
    return api.get('/api_v2/customer/broadcasts/count')
  }
  getBroadcastReset() {
    return api.get('/api_v2/customer/broadcasts/reset')
  }
  // tickets
  getTickets(params) {
    return api.get('/api_v2/customer/tickets', { params: params })
  }
  getTicketCount() {
    return api.get('/api_v2/customer/tickets/count')
  }
  createTicket(data) {
    return api.post('/api_v2/customer/ticket', data)
  }
  preOpenTicket(params) {
    return api.get('/api_v2/customer/pre-open-ticket', { params: params })
  }
  detailTicket(id) {
    return api.get('/api_v2/customer/ticket/' + id)
  }
  closeTicket(val) {
    return api.put(`/api_v2/customer/ticket/${val.ticket_id}/close`, val)
  }
  cancelTicket(id) {
    return api.put(`/api_v2/customer/ticket/${id}/cancel`)
  }
  invoiceTicket(id) {
    return api.get(`/api_v2/invoice/${id}/print`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
        Accept: 'application/pdf'
      }
    })
  }
  getTicketType() {
    return api.get(`/api_v2/ticket_types`)
  }
  getTicketStatuses(id) {
    return api.get(`/api_v2/customer/ticket/${id}/statuses`)
  }

  getTicketServiceReport(id) {
    return api.get(`/api_v2/customer/ticket/${id}/service-reports`)
  }
  getTicketSparePart(val) {
    return api.get(
      `/api_v2/customer/ticket/${val.ticket_id}/service-reports/${val.service_report_id}/parts`
    )
  }
  getAttachmentTypes() {
    return api.get(`/api_v2/customer/ticket-attachment-types`)
  }
  getTicketAttachment(id) {
    return api.get(`/api_v2/customer/ticket/${id}/attachments`)
  }
  uploadTicketAttachment(val) {
    return api.post(`/api_v2/customer/ticket/${val.ticket_id}/attachments`, val.data)
  }
  deleteTicketAttachment(val) {
    return api.delete(
      `/api_v2/customer/ticket/${val.ticket_id}/attachments/${val.attachment_id}/delete`
    )
  }
  downloadTicketAttachment(url) {
    return api.get(url, { responseType: 'blob' })
  }
  downloadExcel(params) {
    return api.get('/api_v2/customer/tickets/export-excel', {
      params: params,
      responseType: 'blob'
    })
  }
  getTicketImg(val) {
    return api.get(`/api_v2/customer/ticket/${val}/show-image`)
  }

  getCommentDraft(val) {
    return api.get('/api_v2/customer/comment-drafts', { params: val })
  }

  // chat
  getTicketChat(id) {
    return api.get(`/api_v2/customer/ticket/${id}/reply`)
  }
  postTicketChat(val) {
    return api.post(`/api_v2/customer/ticket/${val.ticket_id}/reply`, { comment: val.comment })
  }

  // instruments
  getInstruments(params) {
    return api.get('/api_v2/customer/instruments', { params: params })
  }

  checkInstrument() {
    return api.get('/api_v2/customer/instruments/check')
  }
  // divisions
  getDivisions(params) {
    return api.get('/api_v2/divisions', { params: params })
  }
  getInstrumentQR(params) {
    return api.get(`/api_v2/customer/instruments/scan`, { params: params })
  }
  // request type ticket
  getRequestType(division_id) {
    return api.get(`/api_v2/requests/${division_id}/customer`)
  }
  // get departments
  getDepartments() {
    return api.get('/api_v2/departments')
  }
  // get plannings
  getPlannings(params) {
    return api.get('/api_v2/customer/plannings', { params: params })
  }
  // get certificates
  getCertificates(params) {
    return api.get('/api_v2/customer/certificates', { params: params })
  }
  getCertificate(id) {
    return api.get(`/api_v2/customer/certificates/${id}/show`)
  }
  getCaf(params) {
    return api.get('/api_v2/customer/caf-document', { params: params })
  }
  getCafDetail(id) {
    return api.get(`/api_v2/customer/caf-document/${id}`)
  }
  getCafDesignation(params) {
    return api.get('/api_v2/customer/caf-designation', { params: params })
  }
  submitCaf(data) {
    return api.post(`/api_v2/customer/caf-document/${data.id}/submit`, data)
  }
  readCaf(id) {
    return api.put(`/api_v2/customer/caf-document/${id}/readed`)
  }

  aiSend(data) {
    return api.post('/api_v2/api-bot/send', data)
  }
  aiHistory(id) {
    return api.get(`/api_v2/api-bot/chats/${id}`)
  }

  confirmCaf(data) {
    return api.put(`/api_v2/customer/caf-information-confirm/${data.id}`, data)
  }

  submitCafChoiceQuest(data) {
    return api.post(`/api_v2/customer/caf-choice-question`, data)
  }

  submitCafAnswerQuest(data) {
    return api.post(`/api_v2/customer/caf-answer-question`, data)
  }
}

export default new ApiRequest()
