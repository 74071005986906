import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './css/tailwind.css'
import './css/treeselect.css'
import VueAgile from 'vue-agile'
import vuescroll from 'vuescroll'
import VueCompositionAPI from '@vue/composition-api'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// import VueMoment from 'vue-moment'
// import moment from 'moment-timezone'

// Vue.use(VueMoment, {
//   moment
// })

import VueYoutube from 'vue-youtube'
import VueTypedJs from 'vue-typed-js'

Vue.use(VueTypedJs)

Vue.use(VueYoutube)

const toastOpt = {
  position: 'top-center',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: false,
  rtl: false
}

Vue.use(Toast, toastOpt)

Vue.use(require('vue-moment'))

Vue.use(VueCompositionAPI)
Vue.use(vuescroll, {
  name: 'myScroll'
})

Vue.use(VueAgile)

import VCalendar from 'v-calendar'
import axios from 'axios'
window.axios = axios

Vue.config.productionTip = false

Vue.use(VCalendar)

import fMessaging from './firebase'

Vue.prototype.$messaging = fMessaging

// navigator.serviceWorker
//   .register('firebase-messaging-sw.js')
//   .then(registration => {
//     fMessaging.useServiceWorker(registration)
//   })
//   .catch(err => {
//     console.log(err)
//   })

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
})
